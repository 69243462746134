.back-home-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: #4986e7;
  border-radius: 4px;
}
.back-home-btn:hover {
  opacity: 0.7;
}
.back-home-btn a {
  display: block;
  text-decoration: none;
  color: #fff;
  font-size: 32px;
  font-weight: 400;
  padding: 0 12px;
}

